import { useSession, signIn, signOut } from 'next-auth/react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { fetchMessages } from '@appointment/features/appointment/services/appointmentService';
import { allFollowUpMessages } from 'src/common/store/MessageSlice';
import { AppState } from 'src/common/store/index';
import { useDispatch, useSelector } from 'react-redux';
import Notifications from 'src/common/components/notification';
// import MiniChatApp from '@minichatapp-components/MiniChatApp';
import { useState } from 'react';
import Link from 'next/link';
import { permissions, Restricted } from 'src/common/contexts/auth';
import { ISession } from 'src/common/interfaces/session';
import LinkNavigation from './LinkNavigation';
import { setShowMiniChatapp } from '@chatapp-store/chatappSlice';
import Image from 'next/image';
import { hasPermission } from 'src/common/utils/utils';

export default function TopNav({ type }: { type?: string }) {
  const { data: session } = useSession() as unknown as ISession;
  const dispatch = useDispatch();
  const router = useRouter();

  const clinics = useSelector((state: AppState) => state.clinics);
  const { showMiniChatapp } = useSelector((state: AppState) => state.chatApp);
  const { defaultBranch } = clinics;
  useQuery(
    ['followup_messages', defaultBranch?.branch_id],
    () => fetchMessages(defaultBranch?.branch_id, 'followup', true),
    {
      onSuccess: (data) => {
        dispatch(allFollowUpMessages(data.data));
      },
      enabled: !!defaultBranch?.branch_id,
      refetchOnWindowFocus: false,
    },
  );

  const { followUpMessages } = useSelector((state: AppState) => state.message);
  const branchFollowUpMessages = followUpMessages.filter(
    (each) => (each?.branch_id as number) === defaultBranch?.branch_id,
  );

  const [showNotifications, setShowNotifications] = useState(false);

  const handleCloseNotifications = () => setShowNotifications(false);
  const handleShowNotifications = () => {
    setShowNotifications(true);
  };

  const [incidentReportIconHover, setHoveredIncidentReport] = useState(false);

  const unHoverdIncidentReport = () => setHoveredIncidentReport(false);
  const hoverdIncidentReport = () => {
    setHoveredIncidentReport(true);
  };

  // Restricted component cannot be used since we need to check for either MO or CHEW
  const isChew = session
    ? hasPermission(permissions.BMGF_LLM_CHEW, session)
    : null;
  const isMO = session ? hasPermission(permissions.BMGF_LLM_MO, session) : null;
  const isResearchChew = session
    ? hasPermission(permissions.BMGF_LLM_RESEARCH_CHEW, session)
    : null;

  const isBMGFUser = isChew || isMO || isResearchChew;
  const pathname = router.pathname;

  return (
    <>
      <Navbar
        bg="dark"
        variant="dark"
        expand="lg"
        fixed="top"
        className="eha-topnav ps-3">
        {/* <Link href="/" passHref>
          <Navbar.Brand className="ms-2 me-0">
            <Image
              src={require('/public/img/EHACare-logo.svg')}
              width={145}
              height={38}
              className="d-inline-block align-center pt-2"
              alt="Eha Care"
              test-id="ehacare-logo"
            />
          </Navbar.Brand>
        </Link> */}

        {/* Link Navigation */}
        <LinkNavigation section={type ? type : 'ambulatory-app'} />

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mx-3" />
        <Navbar.Collapse id="basic-navbar-nav">
          {session ? (
            <>
              {pathname.split('/')[1] !== 'settings' ? (
                <>
                  <Nav activeKey={router.pathname}>
                    <Restricted to={permissions.BOOKING_VIEW}>
                      <Link href="/appointments" passHref>
                        <Nav.Link test-id="appointments-nav">
                          Appointments
                        </Nav.Link>
                      </Link>
                    </Restricted>

                    <Restricted to={permissions.PATIENTFLOW_DASHBOARD}>
                      <Link href="/ambulatory-app" passHref>
                        <Nav.Link
                          href="/ambulatory-app"
                          test-id="dashboard-nav">
                          Dashboard
                        </Nav.Link>
                      </Link>
                    </Restricted>

                    <Restricted to={permissions.PATIENT_VIEW_LIST}>
                      <Link href="/patients" passHref>
                        <Nav.Link test-id="patients-nav">Patients</Nav.Link>
                      </Link>
                    </Restricted>

                    <Restricted to={permissions.ENCOUNTER_VIEW_LIST}>
                      <Link href="/encounters" passHref>
                        <Nav.Link test-id="encounters-nav">Encounters</Nav.Link>
                      </Link>
                    </Restricted>
                    <Restricted to={permissions.LAB_VIEW}>
                      <NavDropdown
                        title={'Labtests'}
                        id="basic-nav-dropdown"
                        test-id="encounters-nav"
                        renderMenuOnMount>
                        <Link href="/labtests" passHref>
                          <NavDropdown.Item
                            href="#action/3.1"
                            test-id="profile-nav">
                            All Labtests
                          </NavDropdown.Item>
                        </Link>
                        <NavDropdown.Divider />
                        <Restricted to={permissions.EHACare_LAB_}>
                          <Link href="/labtests/recalled" passHref>
                            <NavDropdown.Item
                              href="#action/3.1"
                              test-id="profile-nav">
                              Recalled Labtests
                            </NavDropdown.Item>
                          </Link>
                        </Restricted>
                      </NavDropdown>
                    </Restricted>
                    <Restricted to={permissions.PRESCRIPTION_DISPENSE}>
                      <NavDropdown
                        title={'Prescriptions'}
                        id="basic-nav-dropdown"
                        test-id="encounters-nav"
                        renderMenuOnMount>
                        <Link href="/prescriptions" passHref>
                          <NavDropdown.Item
                            href="#action/3.1"
                            test-id="profile-nav">
                            All Prescriptions
                          </NavDropdown.Item>
                        </Link>
                        <NavDropdown.Divider />
                        <Link href="/prescriptions/refills" passHref>
                          <NavDropdown.Item
                            href="#action/3.1"
                            test-id="profile-nav">
                            Refills
                          </NavDropdown.Item>
                        </Link>
                      </NavDropdown>
                    </Restricted>
                    <Restricted to={permissions.ADMISSION_VIEW}>
                      <Link href="/admissions" passHref>
                        <Nav.Link test-id="admissions-nav">
                          Observations
                        </Nav.Link>
                      </Link>
                    </Restricted>
                    {isBMGFUser && (
                      <Link href="/bmgf" passHref>
                        <Nav.Link test-id="admissions-nav">BMGF</Nav.Link>
                      </Link>
                    )}
                    <Restricted to={permissions.IDOVEN_VIEW}>
                      <Link href="/idoven" passHref>
                        <Nav.Link test-id="idoven-nav">Idoven</Nav.Link>
                      </Link>
                    </Restricted>
                  </Nav>
                </>
              ) : null}

              <Nav activeKey={router.pathname} className="ms-auto">
                <Restricted to={permissions.REPORTS_MENU}>
                  <Link href="/reports/procedures" passHref>
                    <Nav.Link test-id="reports-nav">
                      <i className="eha-report fs-5"></i>
                      <span className="d-lg-none">Reports</span>
                    </Nav.Link>
                  </Link>
                </Restricted>

                {pathname.split('/')[1] !== 'settings' ? (
                  <Link
                    href="https://form.asana.com/?k=yFoooFtDve006Q9_b7yOHA&d=13025191279447"
                    passHref>
                    <Nav.Link
                      test-id="admin-nav"
                      target="_blank"
                      onMouseLeave={unHoverdIncidentReport}
                      onMouseEnter={hoverdIncidentReport}>
                      <Image
                        src={require('/public/img/Incident_Report.svg')}
                        width={20}
                        height={20}
                        alt="Incident report"
                        style={{
                          filter: incidentReportIconHover
                            ? 'brightness(0) saturate(100%) invert(61%) sepia(95%) saturate(1457%) hue-rotate(169deg) brightness(95%) contrast(92%)'
                            : 'none',
                        }}
                      />
                      <span className="d-lg-none">Incident report </span>
                    </Nav.Link>
                  </Link>
                ) : null}
                <NavDropdown
                  title={session.user?.name}
                  id="basic-nav-dropdown"
                  className="user-name"
                  test-id="user-name"
                  renderMenuOnMount>
                  <Link href="/user/profile" passHref>
                    <NavDropdown.Item test-id="profile-nav">
                      Profile
                    </NavDropdown.Item>
                  </Link>
                  <Link
                    href="https://form.asana.com/?k=yFoooFtDve006Q9_b7yOHA&d=13025191279447"
                    passHref>
                    <NavDropdown.Item
                      target="_blank"
                      test-id="send-request-nav">
                      Report Issue
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link href="#" passHref>
                    <NavDropdown.Item
                      onClick={async () => await signOut()}
                      test-id="logout-nav">
                      Log out
                    </NavDropdown.Item>
                  </Link>
                </NavDropdown>
              </Nav>
            </>
          ) : (
            <Nav className="ms-auto">
              <Button size="sm" href="#" onClick={() => signIn('keycloak')}>
                <i className="eha-checkin me-2" />
                Signin
              </Button>
            </Nav>
          )}
        </Navbar.Collapse>
        {pathname.split('/')[1] !== 'settings' ? (
          <Nav.Link href="#" className="chat-app-link">
            <div className="position-relative">
              <div
                onClick={() => {
                  showMiniChatapp
                    ? dispatch(setShowMiniChatapp(false))
                    : dispatch(setShowMiniChatapp(true));
                }}>
                <i className="eha-chat "></i>
                <div className="chat-notify">
                  <i className="eha-circle" />
                </div>
              </div>
            </div>
          </Nav.Link>
        ) : null}
        <Nav.Link
          onClick={handleShowNotifications}
          href="#"
          className="notifications"
          test-id="notification-nav">
          <div>
            <i className="eha-bell-alt text-white fs-4"></i>
            {branchFollowUpMessages.length > 0 && (
              <span className="badge ms-n2 bg-secondary">
                {branchFollowUpMessages.length}
              </span>
            )}
          </div>
        </Nav.Link>
      </Navbar>
      <Offcanvas
        show={showNotifications}
        onHide={handleCloseNotifications}
        placement="end"
        backdropClassName="backdrop-light">
        <Notifications />
      </Offcanvas>

      {/* {showMiniChatapp && <MiniChatApp />} */}
    </>
  );
}
