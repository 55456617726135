export const serviceSwitch = (service: string) => {
  switch (service) {
    case 'Optometrist Consultation':
      return 'eha-ophtalmology';
    case 'Dental Consultation':
      return 'eha-dental';
    case 'Telehealth Consultation':
      return 'eha-telehealth';
    case 'Telespecialist':
      return 'eha-telehealth';
    case 'Doctor Consultation':
      return 'eha-doctor';
    case 'Immunization':
      return 'eha-immunization';
    case 'Family planning':
      return 'eha-pregnant';
    case 'Emergency':
    case 'Elective':
      return 'eha-admission';
    case 'prescription':
      return 'eha-medication';
    case 'labtest':
      return 'eha-lab';
    case 'procedure':
      return 'eha-check-circle';
    case 'imaging':
      return 'eha-file-image';
    default:
      return 'eha-doctor';
  }
};

export const statusSwitch = (status: string) => {
  switch (status) {
    case 'Draft':
    case 'Refill Overdue':
    case 'Not dispensed':
    case 'INACTIVE':
    case 'Emergency':
    case 'Obese':
    case 'Overdue':
      return 'danger';
    case 'In-progress':
    case 'Progress':
    case 'collect-sample':
    case 'Ordered':
    case 'Refill Due':
    case 'Elective':
    case 'Underweight':
    case 'Overweight':
    case 'Due':
      return 'warning';
    case 'Finished':
    case 'Completed':
    case 'Complete':
    case 'Reviewed':
    case 'Dispensed':
    case 'ACTIVE':
    case 'Healthy':
    case 'Normal':
      return 'success';

    default:
      return 'light';
  }
};

export const genderSwitch = (gender: string) => {
  switch (gender) {
    case 'male':
      return 'eha-male';
    case 'female':
      return 'eha-female';
    default:
      return 'eha-neuter';
  }
};

export const smileySwitch = (bmi: string) => {
  switch (bmi) {
    case 'Healthy':
    case 'Normal':
      return 'eha-level1';
    case 'Underweight':
      return 'eha-level2';
    case 'Overweight':
      return 'eha-level2';
    case 'Obese':
      return 'eha-level3';
    default:
      return 'eha-level2';
  }
};
